import React from 'react'
import Footer from '../footer/footer'
import Navigation from '../navigation/navigation'
import PropTypes from 'prop-types'
import styles from './oneColumnLayout.module.css'

const OneColumnLayout = ({ children }) => {
  return (
    <div className={styles.site}>
      <Navigation />
      <main className={styles.siteContent}>{children}</main>
      <Footer />
    </div>
  )
}

OneColumnLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default OneColumnLayout
