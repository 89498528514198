import React from 'react'
import Layout from '../components/oneColumnLayout/OneColumnLayout'
import SEO from '../components/seo'
import styles from '../page-css/404.module.css'

const NotFoundPage = () => (
  <Layout>
    <div className={styles.main}>
      <SEO title="404: Page Not Found" />
      <h1>PAGE NOT FOUND</h1>
      <p>
        <b>
          <i>&#x22;Not all who wander are lost.&#x22;</i>
        </b>
        <br />
        Unfortunately the page you are seeking has wandered too far away.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
